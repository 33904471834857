import { gql } from "@apollo/client";

export const GET_INBOX_CONFIG = gql`
	query getInboxConfig($inboxId: Int!) {
		msgbox_InboxConfib(where: { InboxId: { _eq: $inboxId } }) {
			CRMEmail
			CRMIntegration
			InboxId
			ReapitCustomerId
			ReapitNegotiatorId
			ReapitOfficeId
			BullhornClientId
			BullhornUsername
			BullhornPassword
			BullhornClientSecret
			CRMFrequency
			IsVincereConnected
		}
	}
`;

export const UPDATE_INBOX_CONFIG = gql`
	mutation updateInboxConfig(
		$inboxId: Int!
		$crmEmail: String
		$crmIntegration: String
		$reapitCustomerId: String
		$reapitNegotiatorId: String
		$reapitOfficeId: String
		$autoConversationAssign: Boolean
		$bullhornClientId: String
		$bullhornUsername: String
		$bullhornPassword: String
		$bullhornClientSecret: String
		$crmFrequency: String
	) {
		update_msgbox_InboxConfib(
			where: { InboxId: { _eq: $inboxId } }
			_set: {
				CRMEmail: $crmEmail
				CRMIntegration: $crmIntegration
				ReapitCustomerId: $reapitCustomerId
				ReapitNegotiatorId: $reapitNegotiatorId
				ReapitOfficeId: $reapitOfficeId
				BullhornClientId: $bullhornClientId
				BullhornUsername: $bullhornUsername
				BullhornPassword: $bullhornPassword
				BullhornClientSecret: $bullhornClientSecret
				CRMFrequency: $crmFrequency
			}
		) {
			affected_rows
			returning {
				CRMEmail
				CRMIntegration
				InboxId
				ReapitCustomerId
				ReapitNegotiatorId
				ReapitOfficeId
				BullhornClientId
				BullhornUsername
				BullhornPassword
				BullhornClientSecret
				CRMFrequency
			}
		}
	}
`;

export const INSERT_INBOX_CONFIG = gql`
	mutation insertInboxConfig($inboxId: Int!) {
		insert_msgbox_InboxConfib_one(
			object: {
				# OrganisationId: 1
				InboxId: $inboxId
				# CRMEmail: "agnieszka@quidvista.com"
				# CRMFromEmail: String
				# CRMIntegration: String
				# ReapitCustomerId: String
				# ReapitNegotiatorId: String
				# ReapitOfficeId: String
			}
		) {
			CRMEmail
			# CRMFromEmail
			# CRMIntegration
			# InboxConfigId
			# OrganisationId
			# ReapitCustomerId
			# ReapitNegotiatorId
			# ReapitOfficeId
		}
	}
`;


export const GET_ORG_ID = gql`
	query getOrgId {
		msgbox_Organisation {
			OrganisationId
		}
	}
`;


export const UPDATE_VINCERE_CONNECTED = gql`
	mutation updateInboxConfig(
		$inboxId: Int!
		$IsVincereConnected: Boolean
	) {
		update_msgbox_InboxConfib(
			where: { InboxId: { _eq: $inboxId } }
			_set: {
				IsVincereConnected: $IsVincereConnected
			}
		) {
			affected_rows
			returning {
				IsVincereConnected
			}
		}
	}
`;