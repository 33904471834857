import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Prompt } from "react-router";
import Button from "../../Button/button.component";
import Input from "../../Input/input.component";
import MsgBoxSelect from "../../MsgBoxSelect/msg-box-select.component";
import {
	crmIntegrationMap,
	crmIntegrationOptions,
	crmFrequencyMap,
	crmFrequencyOptions,
	EMAIL_REGEX,
} from "../helper";
import {
	GET_INBOX_CONFIG,
	UPDATE_INBOX_CONFIG,
	GET_ORG_ID,
	UPDATE_VINCERE_CONNECTED,
} from "./query";

const EditInboxSettings = ({ config, id }) => {
	const { addToast } = useToasts();
	const [dirty, setDirty] = useState(false);
	const [emailError, setEmailError] = useState("");
	const [vincereClientId, setVincereClientID] = useState("");
	const [vincereApiKey, setVincereApiKey] = useState("");
	const [vincereTenant, setVincereTenant] = useState("");
	const orgId = useQuery(GET_ORG_ID);
	const [
		updateVincereConnected,
		{ error: vincereError, loading: vincereLoading },
	] = useMutation(UPDATE_VINCERE_CONNECTED);

	function handleVincereOAuth() {
		setDirty(true);
		const jsonState = encodeURIComponent(
			JSON.stringify({
				orgId: orgId.data.msgbox_Organisation[0].OrganisationId,
				inboxId: id,
				clientId: vincereClientId,
				apiKey: vincereApiKey,
				tenant: vincereTenant,
			})
		);

		const uri =
			`https://id.vincere.io/oauth2/authorize?client_id=` +
			vincereClientId +
			"&state=" +
			jsonState +
			`&redirect_uri=${process.env.REACT_APP_VINCERE_REDIRECT_URI}` +
			"&response_type=code";

		window.open(uri, "_blank");
	}

	const [updateInboxConfig, { loading }] = useMutation(UPDATE_INBOX_CONFIG, {
		refetchQueries: [
			{
				query: GET_INBOX_CONFIG,
				variables: {
					inboxId: id,
				},
			},
		],
		onCompleted: () => {
			addToast("Inbox settings updated successfully", {
				appearance: "success",
				autoDismiss: true,
			});
		},
	});

	const handleVincereConnected = async (boolConnected) => {
		setDirty(true);
		try {
			updateVincereConnected({
				variables: {
					inboxId: config.InboxId,
					IsVincereConnected: boolConnected,
				},
			});
		} catch (e) {
			console.log(e);
		}

		if (boolConnected === false) {
			setDirty(true);
		}
		addToast("Connection updated successfully", {
			appearance: "success",
			autoDismiss: true,
		});
	};

	const handleSubmit = async (values) => {
		try {
			const {
				crmEmail,
				crmIntegration,
				reapitCustomerId,
				reapitNegotiatorId,
				reapitOfficeId,
				bullhornClientId,
				bullhornUsername,
				bullhornPassword,
				bullhornClientSecret,
				crmFrequency,
			} = values;

			if (
				(crmIntegration.value === "Rex" ||
					crmIntegration.value === "Hubspot" ||
					crmIntegration.value === "Salesforce" ||
					crmIntegration.value === "Generic Email Forward" ||
					crmIntegration.value === "Email Audit") &&
				!EMAIL_REGEX.test(crmEmail.value)
			) {
				return setEmailError("Invalid Email");
			} else {
				setEmailError("");
			}
			await updateInboxConfig({
				variables: {
					inboxId: id,
					crmEmail: crmEmail.value ? crmEmail.value : null,
					crmIntegration: crmIntegration.value,
					reapitCustomerId: reapitCustomerId.value
						? reapitCustomerId.value
						: null,
					reapitNegotiatorId: reapitNegotiatorId.value
						? reapitNegotiatorId.value
						: null,
					reapitOfficeId: reapitOfficeId.value
						? reapitOfficeId.value
						: null,
					bullhornClientId: bullhornClientId.value
						? bullhornClientId.value
						: null,
					bullhornUsername: bullhornUsername.value
						? bullhornUsername.value
						: null,
					bullhornPassword: bullhornPassword.value
						? bullhornPassword.value
						: null,
					bullhornClientSecret: bullhornClientSecret.value
						? bullhornClientSecret.value
						: null,
					crmFrequency: crmFrequency.value,
				},
			});
			setDirty(false);
		} catch (e) {
			addToast("Something went wrong", {
				appearance: "error",
				autoDismiss: true,
			});
		}
	};
	return (
		<>
			<Formik
				initialValues={{
					crmEmail: {
						value: config.CRMEmail != null ? config.CRMEmail : "",
					},
					crmIntegration: {
						label: crmIntegrationMap[config.CRMIntegration]
							? crmIntegrationMap[config.CRMIntegration]
							: "Vincere",
						value: config.CRMIntegration,
					},
					reapitCustomerId: {
						value:
							config.ReapitCustomerId != null
								? config.ReapitCustomerId
								: "",
					},
					reapitNegotiatorId: {
						value:
							config.ReapitNegotiatorId != null
								? config.ReapitNegotiatorId
								: "",
					},
					reapitOfficeId: {
						value:
							config.ReapitOfficeId != null
								? config.ReapitOfficeId
								: "",
					},
					bullhornClientId: {
						value:
							config.BullhornClientId != null
								? config.BullhornClientId
								: "",
					},
					bullhornUsername: {
						value:
							config.BullhornUsername != null
								? config.BullhornUsername
								: "",
					},
					bullhornPassword: {
						value:
							config.BullhornPassword != null
								? config.BullhornPassword
								: "",
					},
					bullhornClientSecret: {
						value:
							config.BullhornClientSecret != null
								? config.BullhornClientSecret
								: "",
					},
					crmFrequency: {
						label: crmFrequencyMap[config.CRMFrequency],
						value: config.CRMFrequency,
					},
					vincereClientId: {
						label: "Vincere Client Id",
						value: "",
					},
					vincereApiKey: {
						label: "Vincere Api Key",
						value: "",
					},
					vincereTenant: {
						label: "Vincere Tenant",
						value: "",
					},
				}}
				enableReinitialize={true}
				onSubmit={handleSubmit}
			>
				{({ handleSubmit, setFieldValue, values }) => (
					<form
						id={`automation-inbox-settings-${id}`}
						onSubmit={handleSubmit}
					>
						<MsgBoxSelect
							options={crmIntegrationOptions}
							onChange={(options) => {
								setFieldValue("crmIntegration", options);
								setDirty(true);
							}}
							name="crmIntegration"
							label="CRM Integration"
							value={values.crmIntegration}
						/>

						{values.crmIntegration.value === "Rex" ||
						values.crmIntegration.value === "Hubspot" ||
						values.crmIntegration.value === "Salesforce" ||
						values.crmIntegration.value ===
							"Generic Email Forward" ||
						values.crmIntegration.value === "Email Audit" ? (
							<Input
								onChange={(options) => {
									setFieldValue("crmEmail", options.target);
									setDirty(true);
								}}
								name="crmEmail"
								error={emailError}
								label="Email for CRM Integration"
								value={values.crmEmail.value}
							/>
						) : (
							<></>
						)}
						{values.crmIntegration.value !== "None" &&
						values.crmIntegration.value ? (
							<MsgBoxSelect
								toolTipText="Explain here"
								options={crmFrequencyOptions}
								onChange={(options) => {
									setFieldValue("crmFrequency", options);
									setDirty(true);
								}}
								name="crmFrequency"
								label="Frequency"
								value={values.crmFrequency}
							/>
						) : (
							<></>
						)}
						{values.crmIntegration.value === "Reapit" ? (
							<>
								<Input
									onChange={(options) => {
										setFieldValue(
											"reapitCustomerId",
											options.target
										);
										setDirty(true);
									}}
									name="reapitCustomerId"
									label="Reapit Customer Id"
									value={values.reapitCustomerId.value}
								/>
								<Input
									onChange={(options) => {
										setFieldValue(
											"reapitNegotiatorId",
											options.target
										);
										setDirty(true);
									}}
									name="reapitNegotiatorId"
									label="Reapit Negotiator Id"
									value={values.reapitNegotiatorId.value}
								/>
								<Input
									onChange={(options) => {
										setFieldValue(
											"reapitOfficeId",
											options.target
										);
										setDirty(true);
									}}
									name="reapitOfficeId"
									label="Reapit Office Id"
									value={values.reapitOfficeId.value}
								/>
							</>
						) : (
							<></>
						)}

						{values.crmIntegration.value === "Bullhorn" ? (
							<>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										console.log("options:", options.target);
										setFieldValue(
											"bullhornClientId",
											options.target
										);
										setDirty(true);
									}}
									name="bullhornClientId"
									label="Bullhorn Client Id"
									value={values.bullhornClientId.value}
								/>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										console.log("options:", options.target);
										setFieldValue(
											"bullhornClientSecret",
											options.target
										);
										setDirty(true);
									}}
									name="bullhornClientSecret"
									label="Bullhorn Client Secret"
									value={values.bullhornClientSecret.value}
								/>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										console.log("options:", options.target);
										setFieldValue(
											"bullhornUsername",
											options.target
										);
										setDirty(true);
									}}
									name="bullhornUsername"
									label="Bullhorn Username"
									value={values.bullhornUsername.value}
								/>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										console.log("options:", options.target);
										setFieldValue(
											"bullhornPassword",
											options.target
										);
										setDirty(true);
									}}
									name="bullhornPassword"
									label="Bullhorn Password"
									value={values.bullhornPassword.value}
								/>
							</>
						) : (
							<></>
						)}

						{values.crmIntegration.value === "Vincere" ? (
							<div style={{ marginBottom: "50px" }}>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										setVincereClientID(
											options.target.value
										);
										setFieldValue(
											"vincereClientId",
											options.target
										);
										setDirty(true);
									}}
									name="vincereClientId"
									label="Vincere Client ID"
									value={values.vincereClientId.value}
								/>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										setVincereApiKey(options.target.value);
										setFieldValue(
											"vincereApiKey",
											options.target
										);
										setDirty(true);
									}}
									name="vincereApiKey"
									label="Vincere Api Key"
									value={values.vincereApiKey.value}
								/>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										setVincereTenant(options.target.value);
										setFieldValue(
											"vincereTenant",
											options.target
										);
										setDirty(true);
									}}
									name="vincereTenant"
									label="Vincere Tenant"
									value={values.vincereTenant.value}
								/>
								<p style={{ marginBottom: "20px" }}>
									Remember to click 'Save' after you connect!
								</p>
								<Button
									style={{
										float: "left",
										display: "block",
										marginRight: "10px",
									}}
									primary
									buttonName="Connect"
									type="button"
									onClick={handleVincereOAuth}
									loading={loading}
									disabled={
										config.IsVincereConnected === true ||
										vincereApiKey === "" ||
										vincereClientId === "" ||
										vincereTenant === ""
									}
								/>
								<Button
									style={{ float: "left", display: "block" }}
									primary
									buttonName="Disconnect"
									type="submit"
									onClick={() => {
										handleVincereConnected(false);
									}}
									loading={loading}
									disabled={
										config.IsVincereConnected === false
									}
								/>
							</div>
						) : (
							<></>
						)}

						<Button
							style={{ float: "right" }}
							primary
							buttonName="Save"
							type="submit"
							loading={loading}
							disabled={dirty === false}
						/>
						<div style={{ clear: "both" }}></div>
					</form>
				)}
			</Formik>
			<Prompt
				when={dirty}
				message="You have unsaved changes, are you sure you want to leave?"
			/>
		</>
	);
};

export default EditInboxSettings;
