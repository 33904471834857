import * as React from "react";
import { useToasts } from "react-toast-notifications";
import { getAccessToken } from "./functions";

const useUploadFile = () => {
	const [loading, setLoading] = React.useState(false);
	const { addToast } = useToasts();

	const uploadAttachment = React.useCallback(async (file) => {
		try {
			setLoading(true);
			const { name, type } = file;

			const token = await getAccessToken();
			const signedUrlResponse = await fetch(
				`${process.env.REACT_APP_API_URL}filessignedurl`,
				{
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": type,
					},
				}
			);
			const { attachmentId, presignedUrl } =
				await signedUrlResponse.json();
			const attachmentResponse = await fetch(presignedUrl, {
				method: "PUT",
				headers: {
					"Content-Disposition": `filename=${encodeURIComponent(name)}`,
					"Content-Type": type,
				},
				body: file,
			});

			if (
				attachmentResponse.status === 200 ||
				attachmentResponse.status === 201
			) {
				return attachmentId;
			} else {
				addToast("error uploading attachment", {
					appearance: "error",
					autoDismiss: true,
				});
				console.error("failed:", attachmentResponse);
			}
			setLoading(false);
		} catch (e) {
			console.log("error", e);
			addToast("error uploading attachment", {
				appearance: "error",
				autoDismiss: true,
			});
			setLoading(false);
		}
	}, []);

	return { loading, uploadAttachment };
};

export default useUploadFile;
