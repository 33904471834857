import { gql } from "@apollo/client";

export const GET_INBOXES_CONFIG = gql`
	query getInboxesConfig($inboxIds: [Int!]) {
		msgbox_InboxConfib(where: { InboxId: { _in: $inboxIds } }) {
			CRMEmail
			CRMIntegration
			InboxId
			ReapitCustomerId
			ReapitNegotiatorId
			ReapitOfficeId
			BullhornClientId
			BullhornUsername
			BullhornPassword
			BullhornClientSecret
			CRMFrequency
			IsVincereConnected
		}
	}
`;

export const GET_AUTOMATION_CONFIG = gql`
	query getAutomationConfig {
		msgbox_Configuration {
			AutomatedReplyDuration
			AwayReplyDelay
			BotOffAfterBulkTemplateSent
			BotOffAfterTemplateSent
			ConfigurationId
			DefaultReplyDelay
			HumanTakeoverDuration
			AutoAPIConversationAssign
			CRMIntegration
			CRMEmail
			ReapitCustomerId
			ReapitNegotiatorId
			ReapitOfficeId
			AutoConversationAssign
			AutoConversationAssignWhenAssigned
			BullhornClientId
			BullhornUsername
			BullhornPassword
			BullhornClientSecret
			CRMFrequency
			AutoMoveToInbox
			IsVincereConnected
			TreatPresubscribersAsSubscribers
		}
	}
`;

export const UPDATE_AUTOMATION_CONFIG = gql`
	mutation updateAutomationConfig(
		$configurationId: Int!
		$automatedReplyDuration: Int
		$awayReplyDelay: Int
		$botOffAfterBulkTemplateSent: Boolean
		$botOffAfterTemplateSent: Boolean
		$defaultReplyDelay: Int
		$humanTakeoverDuration: Int
		$crmEmail: String
		$crmIntegration: String
		$reapitCustomerId: String
		$reapitNegotiatorId: String
		$reapitOfficeId: String
		$autoConversationAssign: Boolean
		$autoConversationAssignWhenAssigned: Boolean
		$AutoAPIConversationAssign: Boolean
		$bullhornClientId: String
		$bullhornUsername: String
		$bullhornPassword: String
		$bullhornClientSecret: String
		$crmFrequency: String
		$autoMoveToInbox: Boolean
		$treatPresubscribersAsSubscribers: Boolean
	) {
		update_msgbox_Configuration(
			where: { ConfigurationId: { _eq: $configurationId } }
			_set: {
				AutomatedReplyDuration: $automatedReplyDuration
				AwayReplyDelay: $awayReplyDelay
				BotOffAfterBulkTemplateSent: $botOffAfterBulkTemplateSent
				BotOffAfterTemplateSent: $botOffAfterTemplateSent
				DefaultReplyDelay: $defaultReplyDelay
				HumanTakeoverDuration: $humanTakeoverDuration
				CRMEmail: $crmEmail
				CRMIntegration: $crmIntegration
				ReapitCustomerId: $reapitCustomerId
				ReapitNegotiatorId: $reapitNegotiatorId
				ReapitOfficeId: $reapitOfficeId
				AutoConversationAssign: $autoConversationAssign
				AutoConversationAssignWhenAssigned: $autoConversationAssignWhenAssigned
				BullhornClientId: $bullhornClientId
				BullhornUsername: $bullhornUsername
				BullhornPassword: $bullhornPassword
				BullhornClientSecret: $bullhornClientSecret
				CRMFrequency: $crmFrequency
				AutoAPIConversationAssign: $AutoAPIConversationAssign
				AutoMoveToInbox: $autoMoveToInbox
				TreatPresubscribersAsSubscribers: $treatPresubscribersAsSubscribers
			}
		) {
			affected_rows
			returning {
				AutomatedReplyDuration
				AwayReplyDelay
				BotOffAfterBulkTemplateSent
				BotOffAfterTemplateSent
				ConfigurationId
				DefaultReplyDelay
				HumanTakeoverDuration
				CRMEmail
				CRMIntegration
				ReapitCustomerId
				ReapitNegotiatorId
				ReapitOfficeId
				AutoConversationAssign
				AutoConversationAssignWhenAssigned
				BullhornClientId
				BullhornUsername
				BullhornPassword
				BullhornClientSecret
				CRMFrequency
				AutoAPIConversationAssign
				AutoMoveToInbox
				TreatPresubscribersAsSubscribers
			}
		}
	}
`;


export const GET_ORG_ID = gql`
	query getOrgId {
		msgbox_Organisation {
			OrganisationId
		}
	}
`;


export const UPDATE_VINCERE_CONNECTED = gql`
	mutation updateAutomationConfig(
		$configurationId: Int!
		$IsVincereConnected: Boolean
	) {
		update_msgbox_Configuration(
			where: { ConfigurationId: { _eq: $configurationId } }
			_set: {
				IsVincereConnected: $IsVincereConnected
			}
		) {
			affected_rows
			returning {
				IsVincereConnected
			}
		}
	}
`;