import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { useMutation, useQuery } from "@apollo/client";
import { connect, Formik } from "formik";
import {
	automationOptions,
	automationAwayOptions,
	botToggleOptions,
	hoursDaysMap,
	crmIntegrationOptions,
	autoConversationAssignOptions,
	autoConversation2AssignOptions,
	crmIntegrationMap,
	crmFrequencyOptions,
	crmFrequencyMap,
	EMAIL_REGEX,
	autoMoveToInboxOptions,
	treatPresubscribersAsSubscribersOptions,
} from "./helper";
import { Container, LoadingContainer } from "./styles";
import Button from "../Button/button.component";
import Input from "../Input/input.component";
import {
	GET_AUTOMATION_CONFIG,
	UPDATE_AUTOMATION_CONFIG,
	GET_ORG_ID,
	UPDATE_VINCERE_CONNECTED,
} from "./query";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";
import { useToasts } from "react-toast-notifications";
import { useState, useEffect } from "react";
import { Prompt } from "react-router";

const AutomationSettings = () => {
	const [dirty, setDirty] = useState(false);
	const { data, loading, error } = useQuery(GET_AUTOMATION_CONFIG);
	const [vincereClientId, setVincereClientID] = useState("");
	const [vincereApiKey, setVincereApiKey] = useState("");
	const [vincereTenant, setvincereTenant] = useState("");
	const orgId = useQuery(GET_ORG_ID);
	const [emailError, setEmailError] = useState("");
	const [
		updateAutomationConfig,
		{ error: updateError, loading: updateLoading },
	] = useMutation(UPDATE_AUTOMATION_CONFIG, {
		onCompleted: () => {
			addToast("Automations updated successfully", {
				appearance: "success",
				autoDismiss: true,
			});
		},
	});
	const [
		updateVincereConnected,
		{ error: vincereError, loading: vincereLoading },
	] = useMutation(UPDATE_VINCERE_CONNECTED, {
		onCompleted: () => {
			addToast("Vincere configuration saved successfully", {
				appearance: "success",
				autoDismiss: true,
			});
		},
	});

	const { addToast } = useToasts();

	useEffect(() => {
		if (dirty) {
			window.onbeforeunload = () => true;
		}
		return () => {
			window.onbeforeunload = undefined;
		};
	}, [dirty]);

	function handleVincereOAuth() {
		setDirty(true);
		const jsonState = encodeURIComponent(
			JSON.stringify({
				orgId: orgId.data.msgbox_Organisation[0].OrganisationId,
				inboxId: null,
				clientId: vincereClientId,
				apiKey: vincereApiKey,
				tenant: vincereTenant,
			})
		);

		const uri =
			`https://id.vincere.io/oauth2/authorize?client_id=` +
			vincereClientId +
			"&state=" +
			jsonState +
			`&redirect_uri=${process.env.REACT_APP_VINCERE_REDIRECT_URI}` +
			"&response_type=code";

		window.open(uri, "_blank");
	}

	function handleVincereConnect(connectBool) {
		setDirty(true);
		updateVincereConnected({
			variables: {
				configurationId: data.msgbox_Configuration[0].ConfigurationId,
				IsVincereConnected: connectBool,
			},
			update: (cache, data) => {
				cache.modify({
					fields: {
						msgbox_Configuration(existingResponses = []) {
							const newResponseRef = cache.writeQuery({
								data: data.data.update_msgbox_Configuration,
								query: GET_AUTOMATION_CONFIG,
							});
							return [...existingResponses, newResponseRef];
						},
					},
				});
			},
		});
		addToast("Vincere Connection Updated - Please Save Changes", {
			appearance: "success",
			autoDismiss: true,
		});
		if (connectBool === false) {
			setDirty(true);
		}
	}

	const submit = ({
		automatedReplyDuration,
		awayReplyDelay,
		defaultReplyDelay,
		humanTakeOverDuration,
		turnBotOffAfterBulkSendinTemplateMessages,
		turnBotOffAfterSendinTemplateMessage,
		crmEmail,
		crmIntegration,
		reapitCustomerId,
		reapitNegotiatorId,
		reapitOfficeId,
		autoConversationAssign,
		autoConversationAssignWhenAssigned,
		AutoAPIConversationAssign,
		bullhornClientId,
		bullhornUsername,
		bullhornPassword,
		bullhornClientSecret,
		crmFrequency,
		autoMoveToInbox,
		treatPresubscribersAsSubscribers
	}) => {
		if (
			(crmIntegration.value === "Rex" ||
				crmIntegration.value === "Hubspot" ||
				crmIntegration.value === "Salesforce" ||
				crmIntegration.value === "Generic Email Forward" ||
				crmIntegration.value === "Email Audit") &&
			!EMAIL_REGEX.test(crmEmail.value)
		) {
			return setEmailError("Invalid Email");
		} else {
			setEmailError("");
		}

		updateAutomationConfig({
			variables: {
				configurationId: data.msgbox_Configuration[0].ConfigurationId,
				automatedReplyDuration: automatedReplyDuration.value,
				awayReplyDelay: awayReplyDelay.value,
				botOffAfterBulkTemplateSent:
					turnBotOffAfterSendinTemplateMessage.value,
				botOffAfterTemplateSent:
					turnBotOffAfterBulkSendinTemplateMessages.value,
				defaultReplyDelay: defaultReplyDelay.value,
				humanTakeoverDuration: humanTakeOverDuration.value,
				crmEmail: crmEmail.value ? crmEmail.value : null,
				crmIntegration: crmIntegration.value,
				reapitCustomerId: reapitCustomerId.value
					? reapitCustomerId.value
					: null,
				reapitNegotiatorId: reapitNegotiatorId.value
					? reapitNegotiatorId.value
					: null,
				reapitOfficeId: reapitOfficeId.value
					? reapitOfficeId.value
					: null,
				autoConversationAssign: autoConversationAssign.value,
				treatPresubscribersAsSubscribers: treatPresubscribersAsSubscribers.value,
				autoConversationAssignWhenAssigned:
					autoConversationAssignWhenAssigned.value,
				AutoAPIConversationAssign: AutoAPIConversationAssign.value,
				bullhornClientId: bullhornClientId.value
					? bullhornClientId.value
					: null,
				bullhornUsername: bullhornUsername.value
					? bullhornUsername.value
					: null,
				bullhornPassword: bullhornPassword.value
					? bullhornPassword.value
					: null,
				bullhornClientSecret: bullhornClientSecret.value
					? bullhornClientSecret.value
					: null,
				crmFrequency: crmFrequency.value ? crmFrequency.value : null,
				autoMoveToInbox: autoMoveToInbox.value,
			},
			update: (cache, data) => {
				cache.modify({
					fields: {
						msgbox_Configuration(existingResponses = []) {
							const newResponseRef = cache.writeQuery({
								data: data.data.update_msgbox_Configuration,
								query: GET_AUTOMATION_CONFIG,
							});
							return [...existingResponses, newResponseRef];
						},
					},
				});
			},
		});
		setDirty(false);
	};

	if (error) {
		return <p>Ooops... something went wrong..</p>;
	}

	if (updateError) {
	}

	if (loading) {
		return (
			<LoadingContainer>
				<LoadingSpinner />;
			</LoadingContainer>
		);
	}

	const config = data.msgbox_Configuration[0];
	return (
		<Container>
			<h2>Global settings</h2>
			<Formik
				initialValues={{
					defaultReplyDelay: {
						label: hoursDaysMap[config.DefaultReplyDelay],
						value: config.DefaultReplyDelay,
					},
					awayReplyDelay: {
						label: hoursDaysMap[config.AwayReplyDelay],
						value: config.AwayReplyDelay,
					},
					automatedReplyDuration: {
						label: hoursDaysMap[config.AutomatedReplyDuration],
						value: config.AutomatedReplyDuration,
					},
					humanTakeOverDuration: {
						label: hoursDaysMap[config.HumanTakeoverDuration],
						value: config.HumanTakeoverDuration,
					},
					turnBotOffAfterSendinTemplateMessage: {
						label:
							config.BotOffAfterTemplateSent === true
								? "Yes"
								: "No",
						value: config.BotOffAfterTemplateSent,
					},
					treatPresubscribersAsSubscribers: {
						label:
							config.TreatPresubscribersAsSubscribers === true
								? "Yes"
								: "No",
						value: config.TreatPresubscribersAsSubscribers,
					},
					turnBotOffAfterBulkSendinTemplateMessages: {
						label:
							config.BotOffAfterBulkTemplateSent === true
								? "Yes"
								: "No",
						value: config.BotOffAfterBulkTemplateSent,
					},
					crmEmail: {
						value: config.CRMEmail != null ? config.CRMEmail : "",
					},
					crmIntegration: {
						label: crmIntegrationMap[config.CRMIntegration]
							? crmIntegrationMap[config.CRMIntegration]
							: "Vincere",
						value: config.CRMIntegration,
					},

					reapitCustomerId: {
						value:
							config.ReapitCustomerId != null
								? config.ReapitCustomerId
								: "",
					},
					reapitNegotiatorId: {
						value:
							config.ReapitNegotiatorId != null
								? config.ReapitNegotiatorId
								: "",
					},
					reapitOfficeId: {
						value:
							config.ReapitOfficeId != null
								? config.ReapitOfficeId
								: "",
					},
					bullhornClientId: {
						value:
							config.BullhornClientId != null
								? config.BullhornClientId
								: "",
					},
					bullhornUsername: {
						value:
							config.BullhornUsername != null
								? config.BullhornUsername
								: "",
					},
					bullhornPassword: {
						value:
							config.BullhornPassword != null
								? config.BullhornPassword
								: "",
					},
					bullhornClientSecret: {
						value:
							config.BullhornClientSecret != null
								? config.BullhornClientSecret
								: "",
					},
					crmFrequency: {
						label: crmFrequencyMap[config.CRMFrequency],
						value: config.CRMFrequency,
					},
					autoConversationAssign: {
						label:
							config.AutoConversationAssign === true
								? "Yes"
								: "No",
						value: config.AutoConversationAssign,
					},
					autoConversationAssignWhenAssigned: {
						label:
							config.AutoConversationAssignWhenAssigned === true
								? "Yes"
								: "No",
						value: config.AutoConversationAssignWhenAssigned,
					},
					AutoAPIConversationAssign: {
						label:
							config.AutoAPIConversationAssign === true
								? "Yes"
								: "No",
						value: config.AutoAPIConversationAssign,
					},
					autoMoveToInbox: {
						label: config.AutoMoveToInbox === true ? "Yes" : "No",
						value: config.AutoMoveToInbox,
					},
					vincereClientId: {
						label: "Vincere Client Id",
						value: "",
					},
					vincereApiKey: {
						label: "Vincere Api Key",
						value: "",
					},

					vincereTenant: {
						label: "Vincere Tenant",
						value: "",
					},
				}}
				onSubmit={(values, actions) => {
					submit(values);
				}}
			>
				{({ handleSubmit, setFieldValue, values }) => (
					<form id="automation-settings" onSubmit={handleSubmit}>
						<MsgBoxSelect
							options={automationOptions}
							onChange={(options) => {
								setFieldValue("defaultReplyDelay", options);
								setDirty(true);
							}}
							name="defaultReplyDelay"
							label="Default Reply Delay"
							value={values.defaultReplyDelay}
						/>
						<MsgBoxSelect
							options={automationAwayOptions}
							onChange={(options) => {
								setFieldValue("awayReplyDelay", options);
								setDirty(true);
							}}
							name="awayReplyDelay"
							label="Away Reply Delay"
							value={values.awayReplyDelay}
						/>
						<MsgBoxSelect
							options={automationOptions}
							onChange={(options) => {
								setFieldValue(
									"automatedReplyDuration",
									options
								);
								setDirty(true);
							}}
							name="automatedReplyDuration"
							label="Automated Reply Duration"
							value={values.automatedReplyDuration}
						/>
						<MsgBoxSelect
							options={automationOptions}
							onChange={(options) => {
								setFieldValue("humanTakeOverDuration", options);
								setDirty(true);
							}}
							name="humanTakeOverDuration"
							label="Human Takeover Duration"
							value={values.humanTakeOverDuration}
						/>
						<MsgBoxSelect
							toolTipText="Explain here"
							options={botToggleOptions}
							onChange={(options) => {
								setFieldValue(
									"turnBotOffAfterSendinTemplateMessage",
									options
								);
								setDirty(true);
							}}
							name="turnBotOffAfterSendinTemplateMessage"
							label="Turn Bot Off After Sending Template Message"
							value={values.turnBotOffAfterSendinTemplateMessage}
						/>
						<MsgBoxSelect
							toolTipText="Explain here"
							options={botToggleOptions}
							onChange={(options) => {
								setFieldValue(
									"turnBotOffAfterBulkSendinTemplateMessages",
									options
								);
								setDirty(true);
							}}
							name="turnBotOffAfterBulkSendinTemplateMessages"
							label="Turn Bot Off After Bulk Sending Template Messages"
							value={
								values.turnBotOffAfterBulkSendinTemplateMessages
							}
						/>
						<MsgBoxSelect
							toolTipText="Explain here"
							options={autoConversationAssignOptions}
							onChange={(options) => {
								setFieldValue(
									"autoConversationAssign",
									options
								);
								setDirty(true);
							}}
							name="autoConversationAssign"
							label="Automatically assign conversations to sender when not assigned to an individual"
							value={values.autoConversationAssign}
						/>
						<MsgBoxSelect
							toolTipText="Explain here"
							options={autoConversation2AssignOptions}
							onChange={(options) => {
								setFieldValue(
									"autoConversationAssignWhenAssigned",
									options
								);
								setDirty(true);
							}}
							name="autoConversationAssignWhenAssigned"
							label="Automatically assign conversations to most recent sender"
							value={values.autoConversationAssignWhenAssigned}
						/>
						<MsgBoxSelect
							toolTipText="Explain here"
							options={autoConversation2AssignOptions}
							onChange={(options) => {
								setFieldValue(
									"AutoAPIConversationAssign",
									options
								);
								setDirty(true);
							}}
							name="AutoAPIConversationAssign"
							label="Automatically assign conversations sent via the API"
							value={values.AutoAPIConversationAssign}
						/>
						<MsgBoxSelect
							toolTipText="Explain here"
							options={autoMoveToInboxOptions}
							onChange={(options) => {
								setFieldValue("autoMoveToInbox", options);
								setDirty(true);
							}}
							name="autoMoveToInbox"
							label="Move conversations to inbox after sending template message"
							value={values.autoMoveToInbox}
						/>
						<MsgBoxSelect
							toolTipText="Explain here"
							options={treatPresubscribersAsSubscribersOptions}
							onChange={(options) => {
								setFieldValue("treatPresubscribersAsSubscribers", options);
								setDirty(true);
							}}
							name="treatPresubscribersAsSubscribers"
							label="Treat Presubscribers as if they were subscribers. Allows you to broadcast to > 10 at a time."
							value={values.treatPresubscribersAsSubscribers}
						/>
						<hr />
						<MsgBoxSelect
							toolTipText="Explain here"
							options={crmIntegrationOptions}
							onChange={(options) => {
								setFieldValue("crmIntegration", options);
								setDirty(true);
							}}
							name="crmIntegration"
							label="CRM Integration"
							value={values.crmIntegration}
						/>
						{values.crmIntegration.value === "Rex" ||
						values.crmIntegration.value === "Hubspot" ||
						values.crmIntegration.value === "Salesforce" ||
						values.crmIntegration.value ===
							"Generic Email Forward" ||
						values.crmIntegration.value === "Email Audit" ? (
							<Input
								toolTipText="Explain here"
								onChange={(options) => {
									setFieldValue("crmEmail", options.target);
									setDirty(true);
								}}
								name="crmEmail"
								label="Email for CRM Integration"
								value={values.crmEmail.value}
								error={emailError}
							/>
						) : (
							<></>
						)}
						{values.crmIntegration.value !== "None" &&
						values.crmIntegration.value ? (
							<MsgBoxSelect
								toolTipText="Explain here"
								options={crmFrequencyOptions}
								onChange={(options) => {
									setFieldValue("crmFrequency", options);
									setDirty(true);
								}}
								name="crmFrequency"
								label="Frequency"
								value={values.crmFrequency}
							/>
						) : (
							<></>
						)}
						{values.crmIntegration.value === "Reapit" ? (
							<>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										setFieldValue(
											"reapitCustomerId",
											options.target
										);
										setDirty(true);
									}}
									name="reapitCustomerId"
									label="Reapit Customer Id"
									value={values.reapitCustomerId.value}
								/>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										setFieldValue(
											"reapitNegotiatorId",
											options.target
										);
										setDirty(true);
									}}
									name="reapitNegotiatorId"
									label="Reapit Negotiator Id"
									value={values.reapitNegotiatorId.value}
								/>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										setFieldValue(
											"reapitOfficeId",
											options.target
										);
										setDirty(true);
									}}
									name="reapitOfficeId"
									label="Reapit Office Id"
									value={values.reapitOfficeId.value}
								/>
							</>
						) : (
							<></>
						)}
						{values.crmIntegration.value === "Bullhorn" ? (
							<>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										setFieldValue(
											"bullhornClientId",
											options.target
										);
										setDirty(true);
									}}
									name="bullhornClientId"
									label="Bullhorn Client Id"
									value={values.bullhornClientId.value}
								/>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										setFieldValue(
											"bullhornClientSecret",
											options.target
										);
										setDirty(true);
									}}
									name="bullhornClientSecret"
									label="Bullhorn Client Secret"
									value={values.bullhornClientSecret.value}
								/>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										setFieldValue(
											"bullhornUsername",
											options.target
										);
										setDirty(true);
									}}
									name="bullhornUsername"
									label="Bullhorn Username"
									value={values.bullhornUsername.value}
								/>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										setFieldValue(
											"bullhornPassword",
											options.target
										);
										setDirty(true);
									}}
									name="bullhornPassword"
									label="Bullhorn Password"
									value={values.bullhornPassword.value}
								/>
							</>
						) : (
							<></>
						)}

						{values.crmIntegration.value === "Vincere" ? (
							<div style={{ marginBottom: "50px" }}>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										setVincereClientID(
											options.target.value
										);
										setFieldValue(
											"vincereClientId",
											options.target
										);

										setDirty(true);
									}}
									name="vincereClientId"
									label="Vincere Client ID"
									value={values.vincereClientId.value}
								/>
								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										setVincereApiKey(options.target.value);
										setFieldValue(
											"vincereApiKey",
											options.target.value
										);
										setDirty(true);
									}}
									name="vincereApiKey"
									label="Vincere Api Key"
									value={values.vincereApiKey.value}
								/>

								<Input
									toolTipText="Explain here"
									onChange={(options) => {
										setvincereTenant(options.target.value);
										setFieldValue(
											"vincereTenant",
											options.target.value
										);
										setDirty(true);
									}}
									name="vincereTenant"
									label="Vincere Tenant"
									value={values.vincereTenant.value}
								/>
								<p style={{ marginBottom: "20px" }}>
									Remember to click 'Save' after you connect!
								</p>
								<Button
									style={{
										float: "left",
										display: "block",
										marginRight: "10px",
									}}
									primary
									buttonName="Connect"
									type="button"
									onClick={handleVincereOAuth}
									loading={updateLoading}
									disabled={
										config.IsVincereConnected === true ||
										vincereApiKey === "" ||
										vincereClientId === "" ||
										vincereTenant === ""
									}
								/>
								<Button
									style={{ float: "left", display: "block" }}
									primary
									buttonName="Disconnect"
									type="submit"
									onClick={() => handleVincereConnect(false)}
									loading={updateLoading}
									disabled={
										config.IsVincereConnected === false
									}
								/>
							</div>
						) : (
							<></>
						)}
						<Button
							style={{ float: "right" }}
							primary
							buttonName="Save"
							type="submit"
							loading={updateLoading}
							disabled={dirty === false}
						/>
						<div style={{ clear: "both" }}></div>
					</form>
				)}
			</Formik>
			<Prompt
				when={dirty}
				message="You have unsaved changes, are you sure you want to leave?"
			/>
		</Container>
	);
};

export default AutomationSettings;
