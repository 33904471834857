import React, { useState, useEffect, useContext } from "react";
import { FormField, Label, 	msgBoxSelectCustomStyles, } from "../../Styles/global";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import {
	SEARCH_CONTACTS,
	SEARCH_TAGS,
	SEARCH_TEMPLATE_MESSAGES,
	GET_ORG_ID,
	GET_API_ACCOUNT_ID,
	GET_INBOX_NATURAL_CHAT_1,
	GET_CONTACTS_WITH_TAG,
	GET_PRESUBS_FROM_TAGS,
	DELETE_TAG_CONTACT
} from "./query";
import client from "../../Apollo";
import { useFormikContext, Field } from "formik";
import Tooltip from "../Tooltip/tooltip.component";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { GET_ACCOUNT } from "../APIAccountsSelect/query";
import { getUserId } from "../../helpers/functions";
import TextArea from "../TextArea/text-area.component";
import { PHONE_REGEX } from "../../Pages/Contacts/bulk-contact-import/validation";
import { checkContactExists } from "../../helpers/check-contact-exists";
import {
	NATURAL_CHAT_TEMPLATE_TEXT_NAME,
} from "../new-natural-chat/constants";
import {
	GET_TEMPLATE_MESSAGE_BY_NAME,
	GET_TEMPLATE_MESSAGE_BY_NAME_ACC_ID,
} from "../new-natural-chat/query";
import hideNumbers from "../../helpers/hideNumber";
import { UserContext } from "../../Providers/user-provider";
import { GET_AUTOMATION_CONFIG } from "../AutomationSettings/query";
import { ValuesOfCorrectTypeRule } from "graphql";
import { MenuItem } from "@szhsin/react-menu";
import Select from "react-select";
import { useTheme } from "styled-components";

const Step1 = ({
	disableSearchBy,
	disabledFields,
	apiAccountIdProp,
	setNaturalChatTemplate,
	naturalChatTemplate,
	newNaturalChatTemplate,
	setNewNaturalChatTemplate,
	rootStore,
	maxBroadcasts,
	setMaxBroadcasts,
	preSubAsSub,
	setPreSubAsSub,
	setPreSubCount,
	setTagsContactsCount
}) => {

	
	const msgBoxTheme = useTheme();
	const inboxStore = rootStore.getInboxStore()
	const [broadcastLimitMessage, setBroadcastLimitMessage] = useState("")
	const [sendDisabled, setSendDisabled] = useState(false)
	const [tagSplitVisible, setTagSplitVisible] = useState(false)
	const [tagsPreSubCount, setTagsPreSubCount] = useState()
	const [preSubList, setPreSubList] = useState([])
	const [deleteTagContact] = useMutation(DELETE_TAG_CONTACT)
	const [getAutoConfig] = useLazyQuery(GET_AUTOMATION_CONFIG)
	const [templateMessageOptions, setTemplateMessageOptions] = useState([]);
	const [accId, setAccId] = useState(null);
	// const [message, setMessage] = useState("");
	const [filteredTemplateMessageOptions, setFilteredTemplateMessageOptions] =
		useState([]);
	const { data } = useQuery(GET_ORG_ID);
	const [accountOptions, setAccountOptions] = useState([]);
	const context = useContext(UserContext)
	const canSeeNumbers = context.permissions.canSeeNumbers
	const canBulkBroadcast = context.permissions.canBulkBroadcast
	const {
		handleChange,
		setFieldValue,
		values,
		errors,
		setFieldTouched,
		setValues,
		setFieldError,
	} = useFormikContext();


	useEffect(() => {
		loadAccountOptions();
		loadPresubConfig()
	}, []);
	useEffect(() => {
		// Every time values.account changes fetch the templates associated with that accoutn
		if (values.account) {
			// values.account inital value == ""
			loadTemplateMessageOptions();
			// loadTemplateId();

			let maxBroadcast = inboxStore.getMaxBroadcastSize(values.account.inboxId)
			setMaxBroadcasts(maxBroadcast)
		}
	}, [values.account]);
	

	useEffect(()=>{

		if(values.searchBy==="search-contacts")
		{
			setTagSplitVisible(false)
			if(preSubAsSub===false){
				let preSub = values.contacts.filter((contact)=>{
					return contact.status==="Presubscribed"
				})
				setPreSubCount(preSub.length)
			}
		}

		else if(values.searchBy==="search-tags"){
			let tagContactsCount = 0
			values.tags.map((tag)=>{
				tagContactsCount+=tag.userCount
			})
			setTagsContactsCount(tagContactsCount)
			if(preSubAsSub===false){	
			loadTagSplit()
			}
		}

	},[maxBroadcasts, preSubAsSub, values]);


	async function loadTagSplit(){
	if(values.tags.length > 0)
	{
		const tags = values.tags.map((tag) =>
			tag.label.replace(/ *\([^)]*\) *|\*/g, "")
		)
		const contactsWithTags = await client.query({
			query: GET_CONTACTS_WITH_TAG,
			variables: {
				tags,
				account: values.account.value,
			},
			fetchPolicy: "network-only"
		});

		let preSub = contactsWithTags.data.msgbox_Contact.filter((contact)=>{
			return (contact.Status==="Presubscribed")
		})
		setPreSubCount(preSub.length)

		if(preSub.length > 10 && preSubAsSub===false){
			let tempArr = [];

			const promises = preSub.map(async (contact) => {
			  const result = await client.query({
				query: GET_PRESUBS_FROM_TAGS,
				variables: {
				  contactId: contact.ContactId,
				},
			  });
		  
			  if (result.data.msgbox_Contact[0]?.TagContacts?.length > 0) {
				result.data.msgbox_Contact[0]?.TagContacts.forEach((tag) => {
					if(values.tags.some(item => item.value === tag.TagId)){
				  		tempArr.push({
					label: `Remove contact: "${contact.Name}" from tag: "${tag.Tag.Name}"`,
					contactId: contact.ContactId,
					tagId: tag.TagId,
					value: {
						contactId: contact.ContactId,
						tagId: tag.TagId,
					}
				  		});
					}
				});
			  }
			});

			await Promise.all(promises);
			setPreSubList(tempArr)
			setTagSplitVisible(true)
		}
		else{
			setTagSplitVisible(false)
		}
	}
	}

	useEffect(()=>{

		if(preSubAsSub===false){
			if(preSubList.length > 10){
				setTagSplitVisible(true)
			}
			else{
				setTagSplitVisible(false)
			}
		}


	},[preSubList]);

	const handleRemovePreSub = async (selected) =>{

		const contactTagRemove =
    	preSubList.filter((element) => !selected.includes(element))

		//remove from the tag
		const deletionResult = await deleteTagContact({
			variables: {
				tagId: contactTagRemove[0].value.tagId,
				contactId: contactTagRemove[0].value.contactId
			}
		})

		//Optimistic UI feedback 
		values.tags.map((tag)=>{
			if(tag.value===contactTagRemove[0].tagId)
			{
				const nameGrab = tag.label.split('(')[0].trim();
				tag.userCount = tag.userCount-=1
				tag.label = nameGrab +
				" " +
				`(${tag.userCount} Contacts)`
			}
		})
		setPreSubList(selected)
	}



	useEffect(() => {
		if (data && disabledFields.account === true) {
			loadTemplateMessageOptions();
			// loadTemplateId();
		}
	}, [data]);

	async function loadPresubConfig(){
		const result = await getAutoConfig()
		setPreSubAsSub(result.data.msgbox_Configuration[0].TreatPresubscribersAsSubscribers)
	}

	const loadTemplateMessageOptions = async () => {
		let apiAccountId;
		if (apiAccountIdProp) {
			apiAccountId = apiAccountIdProp;
		} else {
			// When sending a template from the inbox page, we dont want to allow the
			// user to select an account. So use the account id from the current inbox
			// if the inbox apiAccountID == null use org-id
			if (disabledFields.account) {
				const response = await client.query({
					query: GET_API_ACCOUNT_ID,
					variables: {
						inboxId: values.account.inboxId,
					},
				});
				if (response.data.msgbox_Inbox[0].APIAccountId) {
					apiAccountId = response.data.msgbox_Inbox[0].APIAccountId;
				} else {
					apiAccountId = data.msgbox_Organisation[0].OrganisationId;
				}
			} else {
				apiAccountId = values.account.value;
			}
		}
		setAccId(apiAccountId);
		const getTemplate = await client.query({
			query: GET_INBOX_NATURAL_CHAT_1,
			variables: {
				accountId: apiAccountId,
			},
		});
		if (getTemplate.data && getTemplate.data.msgbox_UserInbox.length > 0) {
			if (
				getTemplate.data.msgbox_UserInbox[0].Inbox.APIAccount.Templates
					.length > 0
			) {
				setNaturalChatTemplate(
					getTemplate.data.msgbox_UserInbox[0].Inbox.APIAccount
						.Templates[0]
				);
			} else {
				setNaturalChatTemplate(null);
			}
		} else {
			setNaturalChatTemplate(null);
		}

		const response = await client.query({
			query: SEARCH_TEMPLATE_MESSAGES,
			fetchPolicy: "network-only",
			variables: {
				apiAccountId,
				templateTypes: [
					"All",
					"Subscribers Only",
					"Presubscribers Only",
				],
			},
		});

		const options = response.data.msgbox_Template.map((template) => ({
			label: template.Name,
			value: {
				templateId: template.TemplateId,
				name: template.Name,
				text: template.TemplateText,
				attachment: template.Attachment,
				templateType: template.TemplateType.Description,
			},
		}));

		setTemplateMessageOptions(options);
		setFilteredTemplateMessageOptions(options);

		if (values?.account?.inboxId) {
			const getNaturalChatTextTemplate = await client.query({
				query: GET_TEMPLATE_MESSAGE_BY_NAME,
				variables: {
					name: NATURAL_CHAT_TEMPLATE_TEXT_NAME,
					userInboxes: [values.account.inboxId],
				},
			});
			const templateData =
				getNaturalChatTextTemplate.data.msgbox_Template;
			if (templateData && templateData.length > 0) {
				setNewNaturalChatTemplate(templateData[0]);
			} else {
				setNewNaturalChatTemplate(null);
			}
		} else {
			const getNaturalChatTextTemplateByAccId = await client.query({
				query: GET_TEMPLATE_MESSAGE_BY_NAME_ACC_ID,
				variables: {
					name: NATURAL_CHAT_TEMPLATE_TEXT_NAME,
					accountId: apiAccountId,
				},
			});
			const templateData =
				getNaturalChatTextTemplateByAccId.data.msgbox_UserInbox[0].Inbox
					.APIAccount.Templates;

			if (templateData && templateData.length > 0) {
				setNewNaturalChatTemplate(templateData[0]);
			} else {
				setNewNaturalChatTemplate(null);
			}
		}
	};

	const loadContactsOptions = (searchTerm) => {
		return new Promise(async (resolve, reject) => {
			if (values.account) {
				const response = await client.query({
					query: SEARCH_CONTACTS,
					variables: {
						searchTerm: searchTerm + "%",
						accountId: values.account.value,
					},
				});
				const options = response.data.msgbox_Contact.map((contact) => {
				let dontShow = canSeeNumbers
				let subscribedStr = hideNumbers(contact.Name, dontShow) + " " + `(${hideNumbers(contact.MobileNumber, dontShow)})`
				if(contact.Status==="Presubscribed"){
					subscribedStr = subscribedStr+ " *"
				}				
				return	{
					label: subscribedStr,
					value: contact.ContactId,
					status: contact.Status,
				}});

				const isValidNumber = PHONE_REGEX.test(
					searchTerm.trim().replace(" ", "")
				);
				let options1 = options;

				if (isValidNumber && accId) {
					const contactExists = await checkContactExists(
						searchTerm.trim().replace(" ", ""),
						accId
					);
					if (!contactExists) {
						options1.push({
							label: `New Contact: ${searchTerm}`,
							value: searchTerm,
							status: "NEW_CONTACT",
						});
					}
				}

				resolve(options1);
			} else {
				setFieldError(
					"account",
					"You must select an account before searching for tags or contacts"
				);
				reject();
			}
		});
	};

	const loadTagsOptions = (searchTerm, apiAccount) => {
		return new Promise(async (resolve, reject) => {
			if (values.account) {
				const response = await client.query({
					query: SEARCH_TAGS,
					variables: {
						searchTerm: searchTerm + "%",
						accountId: values.account.value,
					},
				});

				// for each item in the response fetch contacts with that tag
				const options = response.data.msgbox_Tag.filter((item) => {
					return canBulkBroadcast ? true  : item.TagContacts_aggregate.aggregate.count <= 10
				}).map((tag) => (
					//label any tags that have 10+ presubscribers in with a * so they can be admin'd if neeeded
					{				
					label: tag.Name +
					" " +
					`(${tag.TagContacts_aggregate.aggregate.count} Contacts)`,
					value: tag.TagId,
					userCount: tag.TagContacts_aggregate.aggregate.count
				}));
				resolve(options);
			} else {
				setFieldError(
					"tags",
					"You must select an account before searching for tags or contacts"
				);
				reject();
			}
		});
	};

	const loadAccountOptions = async () => {
		try {
			const response = await client.query({
				query: GET_ACCOUNT,
				variables: {
					userId: getUserId(),
				},
			});
			const options = response.data.msgbox_User[0].UserInboxes.filter((item) => item.Inbox).map(
				(inbox) => ({
					label: inbox.Inbox.APIAccount.Name,
					value: inbox.Inbox.APIAccount.APIAccountId,
					inboxId: inbox.Inbox.InboxId,
				})
			);
			if (options.length === 1) {
				// If there is only one account preselect the field with that account
				setFieldValue("account", options[0]);
			}
			setAccountOptions(options);
		} catch (error) {
			console.log("err", error);
		}
	};

	/**
	 * @param {string} templateMessage - Template message containing double braces -or not
	 * @description - populate array for each value inside double curley braces.
	 * use array to determine how many / the names of the variable fields
	 * @returns {Array<string>}
	 */
	const parseTemplateMessage = (templateMessage) => {
		const regex = /[^{{\}]+(?=}})/g;
		const results = templateMessage.match(regex);
		return results;
	};

	const handleTemplateMessageChange = (options) => {
		const variables = parseTemplateMessage(options.value.text);
		let variableFields = [];
		if (variables) {
			variableFields = variables.map((variable) => ({
				value: "",
				label: variable,
			}));
		}
		setValues({
			...values,
			...{ variables: variableFields },
			...{ templateMessages: options },
		});
	};

	useEffect(() => {
		if (values.contacts) {
			const hasSubscribers = values.contacts
				.map((x) => x.status)
				.includes("Subscribed");

			const hasNewContacts = values.contacts
				.map((x) => x.status)
				.includes("NEW_CONTACT");

			const hasPresubscribers = values.contacts
				.map((x) => x.status)
				.includes("Presubscribed");

			setFilteredTemplateMessageOptions(
				templateMessageOptions.filter((x) => {
					const array = ["All"];
					if (hasSubscribers) {
						array.push("Subscribers Only");
					}
					if (hasPresubscribers || hasNewContacts) {
						array.push("Presubscribers Only");
					}
					return array.includes(x.value.templateType);
				})
			);
		}
	}, [
		setFieldValue,
		templateMessageOptions,
		values.contacts,
		values.templateMessages,
	]);

	const handleChangeText = (inputText) => {
	const formattedText = inputText.replace(/\n/g, '');
	setFieldValue("message", formattedText)
  };

	return (
		<div>
			{disabledFields.searchBy ? null : (
				<FormField>
					<Label id="search-by">Search by</Label>
					<Tooltip
						text={
							disableSearchBy
								? "This field is disabled. If you would like to send template messages based on tags, go to notifcations page"
								: "Send using tags or contacts"
						}
					/>
					<div
						role="group"
						aria-labelledby="search-by"
						style={{ display: "flex" }}
					>
						<label style={{ marginRight: "24px" }}>
							<input
								disabled={disableSearchBy ? true : false}
								type="radio"
								id="contacts"
								name="searchBy"
								value="search-contacts"
								onChange={handleChange}
								checked={values.searchBy === "search-contacts"}
							/>
							Contacts
						</label>

						<label>
							<input
								disabled={disableSearchBy ? true : false}
								type="radio"
								id="tags"
								name="searchBy"
								value="search-tags"
								onChange={handleChange}
								checked={values.searchBy === "search-tags"}
							/>
							Tags
						</label>
					</div>
				</FormField>
			)}
			{disabledFields.account ? null : (
				<Field
					key={3}
					label="Account"
					name="account"
					onChange={(options, action) => {
						setFieldValue("account", options);
					}}
					onBlur={() => {
						setFieldTouched("account", true);
					}}
					options={accountOptions}
					value={values.account}
					error={errors.account}
					component={MsgBoxSelect}
					toolTipText="Select an account that you wish this message to be sent from"
					hasTooltip
					required
				/>
			)}
			{values.searchBy === "search-contacts" ? 
			(
				disabledFields.contacts ? null : (
					<>
					<Field
						key={1}
						placeholder="Search contacts"
						isMulti
						onChange={(options, action) => {
							let newOptions = canBulkBroadcast ? options : options.slice(0, 10)
							if (values.templateMessages) {
								const hasSubscribers = newOptions
									.map((x) => x.status)
									.includes("Subscribed");

								const hasPresubscribers = newOptions
									.map((x) => x.status)
									.includes("Presubscribed");
								const array = ["All"];
								if (hasSubscribers) {
									array.push("Subscribers Only");
								}
								if (hasPresubscribers) {
									array.push("Presubscribers Only");
								}
								if (
									!array.includes(
										values.templateMessages.value
											.templateType
									)
								) {
									setFieldValue("templateMessages", "");
								}
							}
							
							setFieldValue("contacts", newOptions);
						}}
						onBlur={() => {
							setFieldTouched("contacts", true);
						}}
						loadOptions={loadContactsOptions}
						name="contacts"
						label="Contacts"
						value={values.contacts}
						error={errors.contacts}
						component={MsgBoxSelect}
						async
						required
					/>
					</>

				)
			) : disabledFields.tags ? null : (
				<Field
					key={1}
					placeholder="Search tags"
					isMulti
					onChange={(options, action) => {
						let total = options.reduce((acc, item) => acc + item.userCount, 0);
						let newOptions = options
						if(!canBulkBroadcast && total > 10) {
							newOptions.pop()
						}
						setFieldValue("tags", newOptions);
					}}
					onBlur={() => {
						setFieldTouched("tags", true);
					}}
					loadOptions={loadTagsOptions}
					name="tags"
					label="Tags"
					value={values.tags}
					error={errors.tags}
					component={MsgBoxSelect}
					async
					required
				/>
			)}

			{tagSplitVisible ? (
			<>
			<Label style={{color: "red", fontSize: "10px"}}>Your current setup limits you to sending to 10 presubscribers, please remove some to continue to send this broadcast.
			</Label>
			<div style={{marginBottom: "15px", maxHeight: "150px", overflow: "auto"}}>
			<Select
			isMulti
			isClearable={false}
			value={preSubList}
			onChange={handleRemovePreSub}
			styles={msgBoxSelectCustomStyles(msgBoxTheme)}
			theme={(theme) => ({
				...theme,
				colors: {
					...theme.colors,
					primary25: msgBoxTheme.colours.primaryLight,
					primary: msgBoxTheme.colours.primary,
				},
			})}
			></Select>
			
			</div> </>) : (null)}

			{((naturalChatTemplate || newNaturalChatTemplate) && !disabledFields?.textMessages) && (
				<>
					<TextArea
						defaultValue={values.message}
						label="Send a Message"
						name="message"
						onBlur={() => {
							setFieldTouched("message", true);
						}}
						onChange={(e) => {
							handleChangeText(e.target.value);
						}}
						value={values.message}
						type="text"
						placeholder="Type something to send..."
						error={errors.message}
						required
					/>
					<p style={{ marginBottom: "10px" }}>OR</p>
				</>
			)}

			{disabledFields.templateMessages ? null : (
				<Field
					key={2}
					placeholder="Select Template Messages"
					name="templateMessages"
					options={filteredTemplateMessageOptions}
					onChange={(options, action) => {
						handleTemplateMessageChange(options);
					}}
					onBlur={() => {
						setFieldTouched("templateMessages", true);
					}}
					label="Select template message"
					value={values.templateMessages}
					error={errors.templateMessages}
					component={MsgBoxSelect}
					required
				/>
			)}
		</div>
	);
};

Step1.propTypes = {
	disableSearchBy: PropTypes.bool,
};

Step1.defaultProps = {
	apiAccountIdProp: null,
};

export default Step1;
