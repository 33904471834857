import React, { useEffect, useState, forwardRef } from "react";
import { ModalButtonContainer, ModalButton } from "../MsgBoxModal/styles";
import PropTypes from "prop-types";

const Stepper = forwardRef(
	(
		{
			steps,
			onStepChange,
			validate,
			closeModal,
			hasMessage,
			submitClicked,
		},
		ref
	) => {
		const [currentStep, setCurrentStep] = useState(1);

		const next = async () => {
			if (currentStep === 1) {
				const errors = await validate();
				if (Object.keys(errors).length === 0) {
					setCurrentStep(currentStep + 1);
				}
			}
		};

		const previous = () => {
			if (currentStep !== 1) {
				setCurrentStep(currentStep - 1);
			}
		};
		console.log("steps", steps);

		useEffect(() => {
			onStepChange(currentStep);
		}, [currentStep]);

		return (
			<div>
				{steps.map((step, index) => {
					if (index + 1 === currentStep) {
						return step.component;
					} else return null;
				})}
				{currentStep === 2 ? (
					<div style={{ display: "flex", gap: "6px" }}>
						<ModalButton
							style={{
								flex: 1,
								padding: 0,
							}}
							type="button"
							buttonName="Cancel"
							onClick={() => {
								closeModal();
							}}
						/>
						<div
							style={{
								flex: 1,
								gap: "6px",
								display: "flex",
							}}
						>
							<ModalButton
								onClick={previous}
								type="button 	"
								buttonName="Previous"
								style={{ padding: 0, margin: 0 }}
							/>
							<ModalButton
								buttonName="Submit"
								primary
								style={{ padding: 0, margin: 0 }}
								disabled={submitClicked || false}
							/>
						</div>
					</div>
				) : (
					<ModalButtonContainer>
						<ModalButton
							onClick={closeModal}
							type="button"
							buttonName="Cancel"
						/>
						<div
							style={{
								flex: 1,
								gap: "6px",
								display: "flex",
							}}
						>
							<ModalButton
								onClick={previous}
								type="button"
								buttonName="Previous"
								style={{ padding: 0, margin: 0 }}
							/>
							{hasMessage ? (
								<ModalButton
									buttonName="Send a Message"
									primary
									style={{ padding: 0, margin: 0 }}
								/>
							) : (
								<ModalButton
									onClick={next}
									type="button"
									buttonName={"Next"}
									primary
									style={{ padding: 0, margin: 0 }}
								/>
							)}
						</div>
					</ModalButtonContainer>
				)}
			</div>
		);
	}
);

Stepper.propTypes = {
	onStepChange: PropTypes.func,
	steps: PropTypes.array,
	/**
	 * validateForm function from formik
	 */
	validate: PropTypes.func,
	closeModal: PropTypes.func,
	hasMessage: PropTypes.boolean,
};

export default Stepper;
