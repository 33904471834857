import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { MAX_FILE_SIZE } from "../../helpers/file-upload";
import { getAccessToken } from "../../helpers/functions";
import ButtonWithAbilityToSelect from "../ButtonWithAbilityToSelect/button-with-ability-to-select";
import {
	DEFAULT_ACCEPTED_IMAGE_FORMATS,
	useGlueJar,
} from "../SendDeferredMessage/gluejar";
import { Container } from "./styles";

const InputMessageWithPastingFiles = ({
	sendAttachment,
	message,
	addMessage,
	inputRef,
	handleMessageChange,
	handleInputClick,
	selectedConversation,
	isNaturalChatWindow = true,
}) => {
	const blockPastingFiles = false;
	const { pasted, clearJar } = useGlueJar({ blockPastingFiles });

	const { addToast } = useToasts();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		document.addEventListener("keypress", handleEnterKeyDown);
		return () => {
			document.removeEventListener("keypress", handleEnterKeyDown);
		};
	}, [message]);

	const handleEnterKeyDown = (event) => {
		if (message && selectedConversation) {
			if (event.shiftKey && event.key === "Enter") {
				return;
			} else if (event.key === "Enter") {
				if (document.activeElement === inputRef.current) {
					if (pasted.length <= 0) {
						addMessage();
					} else {
						upload();
					}
				}
			}
		}
	};

	useEffect(() => {
		if (pasted && pasted.length > 0) {
			clearJar();
		}
	}, [selectedConversation]);

	const upload = async () => {
		if (pasted[0].file.size > MAX_FILE_SIZE) {
			addToast("Attachment size too large (max 100MB)", {
				appearance: "error",
				autoDismiss: false,
			});
		} else {
			try {
				setLoading(true);
				const { name, type } = pasted[0].file;
				const signedUrlResponse = await fetch(
					`${process.env.REACT_APP_API_URL}filessignedurl`,
					{
						method: "POST",
						headers: {
							Authorization: `Bearer ${await getAccessToken()}`,
							"content-type": type,
						},
					}
				);
				const { attachmentId, presignedUrl } =
					await signedUrlResponse.json();

				const attachmentResponse = await fetch(presignedUrl, {
					method: "PUT",
					headers: {
						"Content-Disposition": `filename=${name}`,
						"Content-Type": type,
					},
					body: pasted[0].file,
				});

				if (
					attachmentResponse.status === 200 ||
					attachmentResponse.status === 201
				) {
					if (type.includes("image")) {
						await sendAttachment(attachmentId, message);
					} else {
						await sendAttachment(attachmentId, "");
					}
				} else {
					addToast("error uploading attachment", {
						appearance: "error",
						autoDismiss: true,
					});
					console.error("failed:", attachmentResponse);
				}
				clearJar();
				setLoading(false);
			} catch (error) {
				addToast("error uploading attachment", {
					appearance: "error",
					autoDismiss: true,
				});
				setLoading(false);
			}
		}
	};
	//console.log("loading", loading);

	return (
		<Container>
			{pasted.length > 0 &&
				pasted.map((image) => (
					<div key={image.url} className="pasted-container">
						{DEFAULT_ACCEPTED_IMAGE_FORMATS.includes(
							image.file.type
						) ? (
							<img src={image.url} alt={`Pasted: ${image.url}`} />
						) : (
							<div>
								{image.file.type === "application/pdf" ? (
									<i className="fas fa-file-pdf"></i>
								) : (
									<i className="fas fa-file"></i>
								)}
								{image.file.name}
							</div>
						)}
						<button onClick={clearJar}>
							<i className="fas fa-trash"></i>
						</button>
					</div>
				))}
			<div className="text-row">
				<textarea
					onClick={handleInputClick}
					onChange={handleMessageChange}
					type="text"
					ref={inputRef}
					placeholder={
						isNaturalChatWindow
							? "Type something to send... (this is natural chat window)"
							: "Type something to send..."
					}
					value={message}
				/>

				<ButtonWithAbilityToSelect
					form={false}
					hasLabel={false}
					menuPlacement="top"
					height="25px"
					sendMessage={() => {
						if (pasted.length <= 0) {
							addMessage();
						} else {
							upload();
						}
					}}
					loading={loading}
				/>
			</div>
		</Container>
	);
};

export default InputMessageWithPastingFiles;
