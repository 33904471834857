import MsgBoxSelect from "../../MsgBoxSelect/msg-box-select.component";
import { Container } from "../styles";
import { useState } from "react";
import useInboxesOptions from "./use-inboxes-options";
import LoadingSpinner from "../../LoadingSpinner/loading-spinner.component";
import InboxSettings from "./inbox-settings";

const InboxesSettings = () => {
	const [selectedInbox, setSelectedInbox] = useState(null);
	const { inboxesOptions, loading } = useInboxesOptions();

	return (
		<Container style={{ marginTop: "24px" }}>
			<h2>Inboxes settings</h2>
			<h3>
				Any inbox specific settings will override the global settings
				above.
			</h3>
			{loading ? (
				<LoadingSpinner />
			) : (
				<MsgBoxSelect
					options={inboxesOptions}
					onChange={(option) => {
						setSelectedInbox(option);
					}}
					name="selectedInbox"
					label="Select inbox"
					value={selectedInbox}
				/>
			)}
			{selectedInbox && <InboxSettings id={selectedInbox.value} />}
		</Container>
	);
};

export default InboxesSettings;
