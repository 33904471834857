import React from "react";
import { useQuery } from "@apollo/client";
import Chart from "../../../Components/Chart/chart";
import { NoData } from "./no-data";

const SentTemplatesChart = ({data}) => {
	if (!data?.reporting_TemplatesSentByInbox[0]) return <NoData>No Data</NoData>;
	return (
		<Chart
			chartData={data}
			chartDataKey="reporting_TemplatesSentByInbox"
			legendName="Templates Sent"
		/>
	);
};

export default SentTemplatesChart;
