import APIAccountSelect from "../../../Components/APIAccountsSelect/api-accounts-select.component";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_TEMPLATES_BARCHART_DATA } from "./query";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import { ChartContainer } from "../../../Components/Chart/styles";
import { useTheme } from "styled-components";

const TemplatesBarchart = ({ start, end, inboxes }) => {
	const [barchartData, setBarchartData] = useState([]);
	const theme = useTheme();

	const { data, error, loading } = useQuery(GET_TEMPLATES_BARCHART_DATA, {
		variables: {
			start,
			end,
			inboxes,
		},
		fetchPolicy: "no-cache",
		onCompleted: ({ msgbox_BroadcastContact }) => {
			const obj = {};
			const output = [];
			msgbox_BroadcastContact.forEach(({ Broadcast }) => {
				const name = Broadcast.Template
					? Broadcast.Template.Name
					: "Deleted";
				if (obj[name]) {
					obj[name] += 1;
				} else {
					obj[name] = 1;
				}
			});
			for (const template in obj) {
				output.push({
					templateName: template,
					Total: obj[template],
				});
			}
			setBarchartData(output);
		},
	});

	if (error) return null;
	return (
		<ChartContainer>
			{barchartData.length > 0 && (
				<ResponsiveContainer height={428} width="95%">
					<BarChart data={barchartData}>
						<CartesianGrid />
						<XAxis dataKey="templateName" />
						<YAxis />
						<Tooltip />
						<Legend verticalAlign="top" height={36} />
						<Bar dataKey="Total" fill={theme.colours.primary} />
					</BarChart>
				</ResponsiveContainer>
			)}
		</ChartContainer>
	);
};

export default TemplatesBarchart;
