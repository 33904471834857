import { gql } from "@apollo/client";

export const GET_MESSAGE_FLAGS = gql`
	query getMessageStatusFlags($messageId: Int!) {
		msgbox_Message(where: {MessageId: {_eq: $messageId}}) {
		MessageId
		IsDelivered
		IsFailed
		IsRead
		IsSent
		}
	}
	`;
