import { gql } from "@apollo/client";

export const LOAD_INBOXES = gql`
query loadInboxes($userId: Int!) {
    msgbox_UserInbox(where: {UserId: {_eq: $userId}}) {
      InboxId
      UserId
      Inbox {
        UserInboxes(where: {User: {IsAPI: {_eq: false}, IsBot: {_eq: false}, IsDeleted: {_eq: false}}}) {
          UserId
        }
        InboxId
        Name
        Plan {
          Name
          PlanId
          IsLegacyPlan
          IsAIReplyAllowed
          IsAPIAccessAllowed
          IsAnalyticsAccessAllowed
          IsBulkImportContactAllowed
          PlanVariants {
            AllowedTeams
            AllowedUsers
            AllowedChatFlows
            AllowedTemplates
            AllowedBroadcasts
          }
        }
        Folders {
          FolderId
          IsArchive
          IsInbox
          IsSpam
          Name
        }
        APIAccount {
          APIAccountId
          Name
        }
      }
    }
  }  
`;